import React, {
    Component
} from 'react'

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hitokoto: ''
        }
    }

    componentDidMount() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `!function(e,t,n,a,i){var g=n.screen,o=encodeURIComponent,r=["ga=UA-101362832-5","dt="+o(a.title),"dr="+o(a.referrer),"ul="+(i.language||i.browserLanguage||i.userLanguage),"sd="+g.colorDepth+"-bit","sr="+g.width+"x"+g.height,"vp="+Math.max(a.documentElement.clientWidth,n.innerWidth||0)+"x"+Math.max(a.documentElement.clientHeight,n.innerHeight||0),"z="+ +new Date];n.__ga_img=new Image,n.__ga_img.src="https://ga.giuem.com?"+r.join("&")}(0,0,window,document,navigator,location)`
        document.body.appendChild(s);
        document.body.removeChild(s);
        this.fetchHitokoto();
    }

    fetchHitokoto = () => {
        fetch('https://api.itswincer.com/hitokoto/v2/')
            .then(resp => resp.text())
            .then(data => {
                this.setState({
                    hitokoto: data
                })
            })
    }

    render() {
        return (
            <footer>
                <p className="hitokoto">{this.state.hitokoto}</p>
                <p>
                    Copyright © 2017 - {new Date().getFullYear()}, Built with
                {` `}
                    <a href="https://www.itswincer.com">Wincer</a>
                </p>
            </footer>
        )
    }
}

export default Footer;
